<template>
  <div id="carnet-content">
    <div id="carnet">
      <div
        class="carnet-izq"
        :style="{
          'background-image':
            'url(' + require('@/assets/media/rpg/carnet/bg-ladoizq.png') + ')',
        }"
      >
        <div class="datos-carnet">
          <div class="d-flex">
            <p class="text-back">FISHING GEARS</p>
            <p class="back-values">{{ fishingArts }}</p>
          </div>
          <div class="d-flex">
            <p class="text-back">LANDING SITE</p>
            <p class="back-values">{{ fishermen.landingZone }}</p>
          </div>
          <div class="d-flex">
            <p class="text-back">STATUS</p>
            <p class="back-values">{{ fishermen.status }}</p>
          </div>
          <div class="d-flex">
            <p class="text-back">ORGANIZATION</p>
            <p class="back-values">{{ fishermen.organization }}</p>
          </div>
          <div class="d-flex">
            <p class="text-back">OCCRE TYPE</p>
            <p class="back-values">{{ fishermen.occre }}</p>
          </div>
        </div>

        <div class="nota-legal-carnet text-center">
          We Request the Civilian Authorities to provide Cooperation and<br />
          support required by the carrier during the development of the
          activity.
        </div>

        <div class="datos-expedicion-carnet">
          <div class="firma-carnet">
            <v-img
              class="firma-carnet-img"
              :src="firmSecretary"
              width="150"
              alt="firma"
            ></v-img>
            <p class="mt-0 text-center">
              {{ nameSecretary }}
              <br />
              Secretario de Agricultura y Pesca
            </p>
          </div>
        </div>
      </div>
      <div
        class="carnet-der"
        :style="{
          'background-image':
            'url(' + require('@/assets/media/rpg/carnet/bg-ladoder.png') + ')',
          'background-size': 'cover',
        }"
      >
        <v-container class="pa-0">
          <v-row
            align="start"
            style="justify-content: space-around; margin-left: 90px"
          >
            <v-img
              :src="typeActivityImg"
              max-width="170"
              max-height="40"
              alt="RGP"
            ></v-img>
            <v-img
              :src="
                require('@/assets/media/rpg/carnet/logo-gobernacion-sai.png')
              "
              style="margin-right: 30px"
              max-width="190"
              max-height="43"
              alt="RGP"
            ></v-img>
          </v-row>
        </v-container>
        <div class="datos-pescador-carnet">
          <div class="foto-carnet">
            <v-img :src="fishermen.photo"></v-img>
          </div>
          <div class="d-flex" style="display: flex; flex-direction: column">
            <div class="datos-personales-carnet">
              <div class="nombre-pescador-carnet">
                <p class="text-front fs-lg" style="overflow: hidden;">{{ fishermen.name | uppercase }}</p>
                <p class="text-front fs-lg" style="overflow: hidden;">{{ fishermen.lastName | uppercase }}</p>
              </div>

              <div
                class="num-ids-carnet mt-2 d-flex"
              >
                <div class="d-flex" style="flex-direction: column">
                  <p class="text-front fs-18">ID NUMBER</p>
                  <p class="fs-18 mb-0">{{ fishermen.identificationNumber }}</p>
                </div>
                <div>
                  <p class="text-front fs-18 ml-15">CARD No.</p>
                  <p class="fs-18 mb-0">{{ fishermen.fishingCardNumber }}</p>
                </div>
              </div>
              <div>
                <p class="text-front fs-18 mt-1">EXPEDITION PLACE</p>
                <p class="fs-18">{{ fishermen.expeditionPlace | uppercase }}</p>
              </div>
            </div>
            <div
              class="carnet-dates d-flex"
              style="justify-content: space-between"
            >
              <div>ISSUED:{{ fishermen.expeditionDate }}</div>
              <div>EXPIRE: {{ fishermen.expirationDate }}</div>
            </div>
          </div>

          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firmaUrl } from "../../config";
export default {
  props: ["fishermen", "type"],
  computed: {
    typeActivityImg() {
      return this.getTypeActivity(this.type);
    },
    fishingArts() {
      let arts = "";
      const allArts = this.fishermen.fishingArts;
      allArts.forEach((art, i) => {
        arts += art.nombre;
        if (i+1 < allArts.length) {
          arts += ", ";
        }
      });
      return arts;
    },
    firmSecretary() {
      const img = this.$store.state.settings.find(
        (x) => x.nombre == "firma_secretario"
      )?.valor;
      if (img) {
        return firmaUrl + img;
      }
      return "";
    },
    nameSecretary() {
      return this.$store.state.settings.find(
        (x) => x.nombre == "nombre_secretario"
      )?.valor;
    },
  },
  methods: {
    getTypeActivity(type) {
      return (
        {
          art: require("@/assets/media/rpg/carnet/artisanal-card.png"),
          dep: require("@/assets/media/rpg/carnet/sport-card.png"),
          default: require("@/assets/media/rpg/carnet/logo-rgp-carnet.png"),
        }[type] || "default"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.clear {
  clear: both;
}
#carnet-content {
  font-family: Helvetica, Helvetica, sans-serif;
  font-size: 14px;
  white-space: nowrap;
}
#carnet {
  width: 960px;
  margin: 0 auto;
}

.carnet-izq,
.carnet-der {
  border: 1px solid #ddd;
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding: 15px 0;
  height: 300px;
}
.carnet-dates {
  font-size: 13px;
  margin: 0 30px;
}
div.datos-carnet {
  width: 100%;
  margin: 0 0 auto 16px;
}

.text-back {
  color: #1a237e;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  width: 200px;
  flex: 0.8;
  order: 1;
}
.text-front {
  color: #1a237e;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 0;
}

table.datos-carnet td {
  text-align: left;
  padding-left: 10px;
}

.nota-legal-carnet {
  font-size: 12px;
  width: 100%;
  margin: 10px 0 auto;
  background: rgb(225, 225, 225);
  padding: 7px;
  border-radius: 0;
  color: #000000;
  text-align: center;
}

.datos-expedicion-carnet {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.firma-carnet-img {
  margin-left: 40px;
}

.logo-rgp-carnet,
.logo-gob-carnet {
  text-align: right;
}

.foto-carnet {
  width: 4.3cm;
  height: 6cm;
  margin-top: 17px;
  background: #eee;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border: 5px solid #fff;
  float: left;
  overflow: hidden;
}

.foto-carnet img {
  text-align: center;
  width: 130px;
  height: 130px;
}

.datos-personales-carnet {
  float: left;
  padding: 16px 0 0 30px;
  width: 300px;
}

.nombre-pescador-carnet span {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
}

table.num-ids-carnet {
  text-align: left;
  width: 100%;
  border: none;
  border-spacing: 0;
  margin-top: 20px;
}

table.num-ids-carnet th {
  width: 50%;
  font-size: 20px;
  color: #1a237e;
}
.back-values {
  flex: 2;
  order: 2;
  margin-left: 0;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fs-18 {
  font-size: 18px;
}
.fs-lg {
  font-size: 20px;
}
</style>
