var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"carnet-content"}},[_c('div',{attrs:{"id":"carnet"}},[_c('div',{staticClass:"carnet-izq",style:({
        'background-image':
          'url(' + require('@/assets/media/rpg/carnet/bg-ladoizq.png') + ')',
      })},[_c('div',{staticClass:"datos-carnet"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"text-back"},[_vm._v("FISHING GEARS")]),_c('p',{staticClass:"back-values"},[_vm._v(_vm._s(_vm.fishingArts))])]),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"text-back"},[_vm._v("LANDING SITE")]),_c('p',{staticClass:"back-values"},[_vm._v(_vm._s(_vm.fishermen.landingZone))])]),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"text-back"},[_vm._v("STATUS")]),_c('p',{staticClass:"back-values"},[_vm._v(_vm._s(_vm.fishermen.status))])]),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"text-back"},[_vm._v("ORGANIZATION")]),_c('p',{staticClass:"back-values"},[_vm._v(_vm._s(_vm.fishermen.organization))])]),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"text-back"},[_vm._v("OCCRE TYPE")]),_c('p',{staticClass:"back-values"},[_vm._v(_vm._s(_vm.fishermen.occre))])])]),_vm._m(0),_c('div',{staticClass:"datos-expedicion-carnet"},[_c('div',{staticClass:"firma-carnet"},[_c('v-img',{staticClass:"firma-carnet-img",attrs:{"src":_vm.firmSecretary,"width":"150","alt":"firma"}}),_c('p',{staticClass:"mt-0 text-center"},[_vm._v(" "+_vm._s(_vm.nameSecretary)+" "),_c('br'),_vm._v(" Secretario de Agricultura y Pesca ")])],1)])]),_c('div',{staticClass:"carnet-der",style:({
        'background-image':
          'url(' + require('@/assets/media/rpg/carnet/bg-ladoder.png') + ')',
        'background-size': 'cover',
      })},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticStyle:{"justify-content":"space-around","margin-left":"90px"},attrs:{"align":"start"}},[_c('v-img',{attrs:{"src":_vm.typeActivityImg,"max-width":"170","max-height":"40","alt":"RGP"}}),_c('v-img',{staticStyle:{"margin-right":"30px"},attrs:{"src":require('@/assets/media/rpg/carnet/logo-gobernacion-sai.png'),"max-width":"190","max-height":"43","alt":"RGP"}})],1)],1),_c('div',{staticClass:"datos-pescador-carnet"},[_c('div',{staticClass:"foto-carnet"},[_c('v-img',{attrs:{"src":_vm.fishermen.photo}})],1),_c('div',{staticClass:"d-flex",staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"datos-personales-carnet"},[_c('div',{staticClass:"nombre-pescador-carnet"},[_c('p',{staticClass:"text-front fs-lg",staticStyle:{"overflow":"hidden"}},[_vm._v(_vm._s(_vm._f("uppercase")(_vm.fishermen.name)))]),_c('p',{staticClass:"text-front fs-lg",staticStyle:{"overflow":"hidden"}},[_vm._v(_vm._s(_vm._f("uppercase")(_vm.fishermen.lastName)))])]),_c('div',{staticClass:"num-ids-carnet mt-2 d-flex"},[_c('div',{staticClass:"d-flex",staticStyle:{"flex-direction":"column"}},[_c('p',{staticClass:"text-front fs-18"},[_vm._v("ID NUMBER")]),_c('p',{staticClass:"fs-18 mb-0"},[_vm._v(_vm._s(_vm.fishermen.identificationNumber))])]),_c('div',[_c('p',{staticClass:"text-front fs-18 ml-15"},[_vm._v("CARD No.")]),_c('p',{staticClass:"fs-18 mb-0"},[_vm._v(_vm._s(_vm.fishermen.fishingCardNumber))])])]),_c('div',[_c('p',{staticClass:"text-front fs-18 mt-1"},[_vm._v("EXPEDITION PLACE")]),_c('p',{staticClass:"fs-18"},[_vm._v(_vm._s(_vm._f("uppercase")(_vm.fishermen.expeditionPlace)))])])]),_c('div',{staticClass:"carnet-dates d-flex",staticStyle:{"justify-content":"space-between"}},[_c('div',[_vm._v("ISSUED:"+_vm._s(_vm.fishermen.expeditionDate))]),_c('div',[_vm._v("EXPIRE: "+_vm._s(_vm.fishermen.expirationDate))])])]),_c('div',{staticClass:"clear"})])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nota-legal-carnet text-center"},[_vm._v(" We Request the Civilian Authorities to provide Cooperation and"),_c('br'),_vm._v(" support required by the carrier during the development of the activity. ")])
}]

export { render, staticRenderFns }