<template>
  <div id="printMe">
    <v-card>
      <form
        @submit.prevent="submit"
        enctype="multipart/form-data"
        autocomplete="off"
      >
        <v-card-title class="indigo darken-4 white--text">
          <v-icon dark class="mx-4" @click="$router.go(-1)">arrow_back</v-icon>
          {{
            process == "mod"
              ? "Modificar"
              : process == "resolv"
              ? "Resolver"
              : ""
          }}
          Solicitud de
          {{
            type_request == shortTypeRequest.NUEVO
              ? "Registro"
              : type_request == shortTypeRequest.PRORROGA
              ? "Prorroga"
              : type_request == shortTypeRequest.DUPLICADO
              ? "Duplicado y/o Modificación"
              : type_request == shortTypeRequest.CANCELACION_PERMISO
              ? "Cancelación"
              : type_request == shortTypeRequest.REACTIVACION_PERMISO
              ? "Reactivación"
              : ""
          }}
          de Pescador
          {{
            type == "art"
              ? "Artesanal"
              : type == "ind"
              ? "Industrial"
              : type == "dep"
              ? "Deportivo"
              : ""
          }}
          {{ "-" + process != "new" ? fishin_card_number : "" }}
        </v-card-title>
        <v-container grid-list-sm>
          <v-layout row wrap>
            <v-alert v-if="wasSaved" type="success"
              >Pescador fue guardado correctamente</v-alert
            >

            <v-flex xs12 class="mb-4">
              <v-subheader light class="subtitle-1 font-italic font-weight-bold"
                >DATOS DE LA SOLICITUD</v-subheader
              >
              <v-divider light></v-divider>
            </v-flex>

            <v-flex xs4>
              <v-text-field
                prepend-inner-icon="info"
                placeholder="# Radicado"
                hint="# Radicado"
                persistent-hint
                :readonly="process == 'resolv'"
                v-model="filed"
                :clearable="process == 'resvol'"
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-select
                :readonly="process == 'resolv'"
                v-model="select_location"
                prepend-inner-icon="map"
                hint="Municipio"
                :items="itemsRequestFishermen.items_select_location"
                @change="certification_issued_by = ''"
                item-text="state"
                item-value="abbr"
                return-object
                single-line
                persistent-hint
              ></v-select>
            </v-flex>

            <v-flex xs4>
              <v-menu
                :disabled="process == 'resolv'"
                v-model="menu_date_filed"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date_filed"
                    hint="Fecha de Radicado"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :readonly="process == 'resolv'"
                  v-model="date_filed"
                  @input="menu_date_filed = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 class="mb-4">
              <v-subheader light class="subtitle-1 font-italic font-weight-bold"
                >DOCUMENTOS DE LA SOLICITUD</v-subheader
              >
              <v-divider light></v-divider>
            </v-flex>
            <v-divider></v-divider>

            <v-flex xs3>
              <v-radio-group
                :readonly="process == 'resolv'"
                v-model="copy_identification_document"
                :mandatory="false"
                row
                persistent-hint
                hint="Copia del Documento de Identificación"
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
              </v-radio-group>
            </v-flex>

            <v-flex xs3 v-if="type != 'dep'">
              <v-radio-group
                :readonly="process == 'resolv'"
                v-model="copy_occre"
                :mandatory="false"
                row
                persistent-hint
                hint="Copia OCCRE"
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
              </v-radio-group>
            </v-flex>

            <v-flex xs3 v-if="type != 'dep'">
              <v-radio-group
                :readonly="process == 'resolv'"
                v-model="copy_sisben"
                row
                persistent-hint
                hint="Fotocopia de Afiliación de Salud"
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs3>
              <v-radio-group
                :readonly="process == 'resolv' || readonly"
                v-model="accreditation_certificate"
                :mandatory="false"
                row
                persistent-hint
                hint="Certificación/Acreditación"
                @change="setValidateCertificationIssuedBy()"
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex
              xs6
              v-if="type == 'art' && accreditation_certificate == 'SI' && certification_issued_by == ''"
            >
              <v-select
                v-if="accreditation_certificate == 'SI'"
                :disabled="accreditation_certificate != 'SI'"
                :readonly="process == 'resolv' || readonly"
                v-model="select_certification_issued_by"
                :items="itemsSelectOrganizations"
                item-text="state"
                item-value="abbr"
                hint="Certificación expedida por"
                @change="validateOrganization"
                persistent-hint
                small-chips
              ></v-select>
            </v-flex>
            <v-flex xs6 class="mr-4" v-else>
              <v-text-field
                v-model="certification_issued_by"
                v-if="accreditation_certificate == 'SI'"
                :readonly="process == 'resolv' || readonly"
                :disabled="accreditation_certificate != 'SI'"
                placeholder="Certificación expedida por"
                persistent-hint
                hint="Certificación expedida por"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="mb-4">
              <v-subheader light class="subtitle-1 font-italic font-weight-bold"
                >DATOS DEL PERMISO</v-subheader
              >
              <v-divider light></v-divider>
            </v-flex>

            <v-flex xs4 v-if="type != 'ind'">
              <v-select
                :readonly="readonly"
                v-model="select_validity_permit"
                prepend-inner-icon="map"
                hint="Vigencia del Permiso (años)"
                :items="
                  type != 'dep'
                    ? itemsRequestFishermen.items_select_validity_permit
                    : itemsRequestFishermen.items_select_validity_permit_dep
                "
                item-text="state"
                item-value="abbr"
                persistent-hint
                return-object
                @change="dateFormatted"
                single-line
              ></v-select>
            </v-flex>

            <v-flex xs4 v-else>
              <v-select
                :readonly="readonly"
                v-model="select_validity_permit"
                prepend-inner-icon="map"
                hint="Vigencia del Permiso (meses)"
                :items="itemsRequestFishermen.items_select_validity_permit_ind"
                item-text="state"
                item-value="abbr"
                persistent-hint
                return-object
                @change="dateFormatted"
                single-line
              ></v-select>
            </v-flex>

            <v-flex xs4>
              <v-menu
                v-model="menu_date_expedition"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="process == 'resolv' || type_request == 'c'"
                    v-model="date_expedition"
                    hint="Fecha de Expedición"
                    persistent-hint
                    prepend-icon="event"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_expedition"
                  @change="dateFormatted"
                  @input="menu_date_expedition = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs4>
              <v-menu
                v-model="menu_date_expiration"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                readonly
                disabled
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date_expiration"
                    hint="Fecha de Expiración"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFormatted"
                  @input="menu_date_expiration = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 class="mb-4">
              <v-subheader light class="subtitle-1 font-italic font-weight-bold"
                >IDENTIFICACIÓN DEL PESCADOR</v-subheader
              >
              <v-divider light></v-divider>
            </v-flex>

            <v-flex xs12>
            <preview-license :type="type" :fishermen="fishermenLicence" v-show="type != 'ind'"></preview-license>

            </v-flex>

            <v-flex v-show="type != 'ind'" xs12 class="mt-6">
              <v-file-input
                :disabled="process == 'resolv'"
                label="Foto"
                @change="getImage"
                accept="image/*"
                show-size
                filled
                @click:clear="clearPhoto"
                :clearable="process !== 'resolv'"
              ></v-file-input>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                prepend-inner-icon="fa-user"
                placeholder="Nombre(s) del Pescador"
                hint="Nombre(s) del Pescador"
                :readonly="process == 'resolv'"
                v-model="name"
                :clearable="clearable"
                persistent-hint
              ></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field
                prepend-inner-icon="fa-user"
                placeholder="Apellido(s) del Pescador"
                hint="Apellido(s) del Pescador"
                :readonly="process == 'resolv'"
                v-model="lastname"
                :clearable="clearable"
                persistent-hint
              ></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-combobox
                :readonly="process == 'resolv'"
                v-model="select_type_document"
                :items="itemsRequestFishermen.items_select_type_document"
                hide-selected
                item-text="state"
                item-value="abbr"
                hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                label="Tipo de Documento de Identificación Personal"
                persistent-hint
                small-chips
              ></v-combobox>
            </v-flex>

            <v-flex xs6>
              <v-text-field
                :readonly="process == 'resolv' || type_request != 'UNO'"
                
                v-model="identification_number"
                :error-messages="erroresIdentificationNumber"
                prepend-inner-icon="fa-id-card"
                label="Número de Identificación"
                :clearable="clearable"
                hint="Número de Identificación"
                persistent-hint
              ></v-text-field>
            </v-flex>

            <v-flex xs4 v-if="type == 'dep'">
              <v-select
                :readonly="process == 'resolv' || readonly"
                v-model="select_type_person"
                prepend-inner-icon="map"
                hint="Tipo de persona"
                :items="items_select_type_person"
                item-text="state"
                item-value="abbr"
                label="Tipo de persona"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-flex>

            <v-flex xs4>
              <v-radio-group
                :readonly="process == 'resolv' || readonly"
                v-model="occre"
                :error-messages="erroresOccre"
                row
                hint="Clasificación certificación OCCRE"
                persistent-hint
              >
                <v-radio label="Raizal" value="RAIZAL"></v-radio>
                <v-radio label="Residente" value="RESIDENTE"></v-radio>
                <v-radio
                  v-if="type == 'ind'"
                  label="Permiso de Trabajo"
                  value="PERMISO_TRABAJO"
                ></v-radio>
              </v-radio-group>
            </v-flex>

            <v-flex xs4 v-if="type != 'dep'">
              <v-text-field
                
                :readonly="process == 'resolv' || readonly"
                v-model="no_occre"
                :error-messages="erroresNoOccre"
                prepend-inner-icon="fa-barcode"
                placeholder="No. OCCRE"
                label="No. OCCRE"
                :clearable="clearable"
                hint="Número de serie unico que identifica 
                a una persona raizal y/o residente en el Departamento Archipielago de San Andres, 
                Providencia y Santa Catalina"
              ></v-text-field>
            </v-flex>

            <v-flex xs4>
              <v-select
                :readonly="process == 'resolv' || readonly"
                v-model="select_nationality"
                prepend-inner-icon="fa-flag"
                persistent-hint
                hint="Nacionalidad"
                :items="itemsRequestFishermen.items_select_nationality"
                item-text="state"
                item-value="abbr"
                label="Nacionalidad"
                @change="Calculate()"
                return-object
                single-line
              ></v-select>
            </v-flex>

            <v-flex>
              <v-text-field
                
                :readonly="process == 'resolv' || readonly"
                v-model="email"
                :error-messages="erroresEmail"
                prepend-inner-icon="email"
                placeholder="Email"
                label="Email"
                :clearable="clearable"
                persistent-hint
              ></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field
                
                :readonly="process == 'resolv' || readonly"
                v-model="address"
                :error-messages="erroresAddress"
                prepend-inner-icon="fa-map-marker-alt"
                placeholder="Dirección de la Residencia"
                label="Dirección de la Residencia"
                :clearable="clearable"
                persistent-hint
              ></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field
                
                :readonly="process == 'resolv' || readonly"
                v-model="phone"
                prepend-inner-icon="phone"
                placeholder="Número de Teléfono"
                label="Número de Teléfono"
                :clearable="clearable"
                persistent-hint
              ></v-text-field>
            </v-flex>

            <v-flex xs6 v-if="type != 'ind'">
              <v-radio-group
                :readonly="process == 'resolv' || readonly"
                v-model="organization_cooperative"
                @change="hiddenOrganization"
                :mandatory="false"
                :disabled="this.certification_issued_by == 'DECLARACIÓN JURAMENTADA'"
                row
                persistent-hint
                hint="Organización / Cooperativa"
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
              </v-radio-group>
            </v-flex>

            <v-flex xs4 v-if="(type != 'ind') && (organization_cooperative == 'SI' || organization_cooperative == '')">
              <v-select
                :readonly="process == 'resolv' || readonly" 
                v-model="select_organization"
                :items="itemsSelectOrganizationsIndep"
                item-text="state"
                item-value="abbr"
                hint="Nombre de la organización"
                persistent-hint
                small-chips
              ></v-select>
            </v-flex>

            <v-flex xs12 class="mb-4">
              <v-subheader light class="subtitle-1 font-italic font-weight-bold"
                >DESCRIPCIÓN ACTIVIDAD PESQUERA</v-subheader
              >
              <v-divider light></v-divider>
            </v-flex>
            <v-divider></v-divider>
            <v-flex xs6 v-if="type != 'ind'">
              <v-combobox
                v-if="type == 'art'"
                :readonly="process == 'resolv' || readonly"
                v-model="select_landing_zone"
                :items="items_select_landing_zone"
                item-text="nombre"
                item-value="codigo"
                hint="Zona de desembarco"
                persistent-hint
                small-chips
              ></v-combobox>

              <v-text-field
                
                v-if="type == 'dep'"
                :readonly="process == 'resolv' || readonly"
                v-model="landing_zone"
                placeholder="Zona de desembarco"
                :clearable="clearable"
                hint="Zona de desembarco"
                persistent-hint
              ></v-text-field>
            </v-flex>

            <v-flex xs6 v-if="type == 'art'">
              <v-text-field
                
                :readonly="process == 'resolv' || readonly"
                v-model="frequent_fishing_area"
                prepend-inner-icon="mdi-fish"
                placeholder="Zona frecuente de pesca"
                label="Zona frecuente de pesca"
                :clearable="clearable"
                persistent-hint
              ></v-text-field>
            </v-flex>

            <v-flex xs6 v-if="type == 'art'">
              <v-combobox
                :readonly="process == 'resolv' || readonly"
                v-model="select_type_of_boat"
                :items="itemsRequestFishermen.items_select_type_of_boat"
                hide-selected
                item-text="state"
                item-value="abbr"
                hint="Tipo de embarcación artesanal"
                persistent-hint
                small-chips
              ></v-combobox>
            </v-flex>

            <v-flex xs6 v-if="type != 'dep'">
              <v-combobox
                :readonly="process == 'resolv' || readonly"
                v-model="select_type_of_fishing"
                :items="itemsRequestFishermen.items_select_type_of_fishing"
                item-text="nombre"
                item-value="codigo"
                label="Tipo de pesquería"
                hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                persistent-hint
                return-object
                single-line
                multiple
                chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    @click="data.select"
                  >
                    {{
                      data.item.pivot !== undefined
                        ? data.item.pivot.description
                        : data.item.nombre !== undefined
                        ? data.item.nombre
                        : data.item
                    }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-flex>

            <v-flex xs6 v-if="type != 'ind'">
              <v-combobox
                :readonly="process == 'resolv' || readonly"
                v-model="select_fishing_arts"
                :items="items_select_fishing_arts"
                item-text="nombre"
                item-value="codigo"
                hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                label="Artes de pesca"
                persistent-hint
                return-object
                single-line
                multiple
                chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    @click="data.select"
                  >
                    {{
                      ((data.item || {}).pivot || {}).description ||
                      (data.item || {}).nombre ||
                      data.item
                    }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-flex>
            <v-flex xs12></v-flex>
            <v-flex xs3 v-if="type == 'art'">
              <v-radio-group
                :readonly="process == 'resolv' || readonly"
                v-model="report_boat"
                row
                persistent-hint
                hint="Reporta Embarcación"
                @change="cleanInputBoat"
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs12 class="mb-4" v-if="type == 'ind'">
              <v-subheader
                light
                class="subtitle-1 font-italic font-weight-bold"
              >
                DATOS DEL PERMISIONARIO
                <v-spacer></v-spacer>
                <v-btn
                  v-if="type == 'ind' && process != 'resolv'"
                  small
                  color="indigo darken-4 white--text"
                  dark
                  class="float--left"
                  @click="dialogPermisionario = true"
                >
                  <v-icon small>fa fa-search</v-icon>Buscar
                </v-btn>
                <v-dialog
                  v-model="dialogPermisionario"
                  persistent
                  max-width="700px"
                >
                  <v-card>
                    <v-card-title
                      class="headline indigo darken-4 white--text"
                      primary-title
                      >Buscar Permisionario</v-card-title
                    >

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="searchNit"
                              label="NIT o Nombre del permisionario"
                              required
                              v-debounce:200ms="searchPermisionario"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-list>
                              <v-list-item-group
                                v-model="model"
                                active-class="border"
                                color="indigo"
                              >
                                <v-list-item
                                  v-for="(item, i) in permittees"
                                  :key="i"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="item.name + '-' + item.nit"
                                      @click="selectBoat(item)"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogPermisionario = false"
                        >Cerrar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-subheader>
            </v-flex>
            <v-flex xs6 v-if="type == 'ind'">
              <v-text-field
                readonly
                v-model="company_id"
                placeholder="NIT"
                label="NIT"
                persistent-hint
              ></v-text-field>
            </v-flex>
            <v-flex xs6 v-if="type == 'ind'">
              <v-text-field
                readonly
                v-model="name_permissionario"
                placeholder="Permisionario"
                label="Permisionario"
                persistent-hint
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="mb-4" v-if="type != 'dep'">
              <v-subheader light class="subtitle-1 font-italic font-weight-bold"
                >EMBARCACIONES</v-subheader
              >
              <v-divider light></v-divider>
            </v-flex>

            <v-flex
              xs12
              class="mt-5"
              v-if="(type == 'art' && report_boat == 'SI') || type == 'ind'"
            >
              <v-dialog
                v-model="dialogBoat"
                persistent
                max-width="850"
                v-if="type == 'art' && report_boat == 'SI'"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="float-right"
                    small
                    color="indigo darken-4 white--text"
                    dark
                    v-on="on"
                    v-if="process != 'resolv'"
                  >
                    <v-icon small>fa fa-plus</v-icon>Agregar
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title
                    class="headline indigo darken-4 white--text"
                    primary-title
                    >Agregar Embarcación</v-card-title
                  >
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-flex xs5 class="mr-3">
                          <v-radio-group
                            v-model="owns_boat"
                            row
                            persistent-hint
                            hint="Propietario de la Embarcación"
                          >
                            <v-radio label="Si" value="1"></v-radio>
                            <v-radio label="No" value="0"></v-radio>
                          </v-radio-group>
                        </v-flex>
                        <v-flex xs6>
                          <v-combobox
                            v-model="select_type_accredit"
                            :items="
                              itemsRequestFishermen.items_select_type_accredit
                            "
                            hide-selected
                            hint="Documento que lo acredita"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-flex>
                        <v-flex xs12>
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                            >Buscar Embarcaciones en SAI</v-subheader
                          >
                          <v-divider light></v-divider>
                        </v-flex>
                        <v-flex xs12>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="searchValueBoat"
                                  hint="Buscar Embarcación"
                                  label="Número de matrícula o nombre de la embarcación"
                                  @keyup="searchBoat"
                                  prepend-icon="search"
                                  v-debounce:200ms="searchBoat"

                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <v-list>
                                  <v-list-item-group
                                    active-class="border"
                                    color="indigo"
                                  >
                                    <v-list-item
                                      v-for="(item, i) in boats_find"
                                      :key="i"
                                    >
                                      <v-list-item-content
                                        @click="setBoat(item)"
                                      >
                                        <v-list-item-title
                                          v-text="
                                            item.registration_number +
                                            '-' +
                                            item.boat_name
                                          "
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item-group>
                                </v-list>
                                <span>{{ messageNotFoundBoat }}</span>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogBoat = false"
                      >Cerrar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-simple-table :dense="false" :fixed-header="true">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th scope="th-propietario" v-if="type == 'art'">
                        Es Propietario
                      </th>
                      <th scope="th-documento" v-if="type == 'art'">
                        Documento de Acredita
                      </th>
                      <th scope="th-nombre">Nombre</th>
                      <th scope="th-matricula">N° Matrícula</th>
                      <th scope="th-action"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in fishermenBoats" :key="index">
                      <td v-if="type == 'art'">
                        {{
                          item.pivot != undefined
                            ? item.pivot.propietario == 1
                              ? "SI"
                              : "NO"
                            : item.owns_boat == 1
                            ? "SI"
                            : "NO"
                        }}
                      </td>

                      <td v-if="type == 'art'">
                        {{
                          item.pivot != undefined
                            ? item.pivot.tipo_certificado
                            : item.owner_acredit
                        }}
                      </td>

                      <td>{{ item.boat_name }}</td>

                      <td>{{ item.registration_number }}</td>

                      <td
                        v-if="type == 'ind'"
                        class="float-right"
                        @click="checkBoat"
                      >
                        <v-checkbox
                          title="Checkear"
                          v-model="checkBoats"
                          color="indigo"
                          :readonly="process == 'resolv'"
                          :value="item.id"
                        ></v-checkbox>
                      </td>
                      <td
                        v-if="type == 'art' && process != 'resolv'"
                        class="float-right"
                      >
                        <v-btn small class="primary" @click="removeBoat(index)"
                          >Eliminar</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>

            <v-flex xs12 class="mb-4" v-if="type != 'dep'">
              <v-subheader light class="subtitle-1 font-italic font-weight-bold"
                >INFORMACIÓN SOCIOECONÓMICA</v-subheader
              >
              <v-divider light></v-divider>
            </v-flex>
            <v-divider></v-divider>
            <v-flex xs4 v-if="type != 'dep'">
              <v-menu
                :disabled="readonly"
                v-model="menu_birth_date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :clearable="type_request != 'c'"
                    v-model="birth_date"
                    hint="Fecha de nacimiento"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="birth_date"
                  @input="menu_birth_date = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs4 v-if="type != 'dep'">
              <v-text-field
                disabled="true"
                readonly
                v-model="age"
                :error-messages="erroresAge"
                prepend-inner-icon="how_to_reg"
                hint="Edad"
                label="Edad"
                :clearable="clearable"
                persistent-hint
              ></v-text-field>
            </v-flex>

            <v-flex xs4 v-if="type != 'dep'">
              <v-select
                :readonly="process == 'resolv' || readonly"
                v-model="select_gender"
                prepend-inner-icon="mdi-face"
                hint="Sexo"
                persistent-hint
                :items="itemsRequestFishermen.items_select_gender"
                item-text="state"
                item-value="abbr"
                label="Sexo"
                return-object
                single-line
              ></v-select>
            </v-flex>

            <v-flex xs6 v-if="type == 'art'">
              <v-select
                :readonly="process == 'resolv' || readonly"
                v-model="select_marital_status"
                prepend-inner-icon="mdi-face"
                hint="Estado Civil"
                persistent-hint
                :items="itemsRequestFishermen.items_select_marital_status"
                item-text="state"
                item-value="abbr"
                label="Estado Civil"
                return-object
                single-line
              ></v-select>
            </v-flex>

            <v-flex xs6 v-if="type == 'art'">
              <v-radio-group
                :readonly="process == 'resolv' || readonly"
                v-model="read_and_write"
                row
                persistent-hint
                hint="Lee y Escribe"
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="Sin Respuesta" value="NR"></v-radio>
              </v-radio-group>
            </v-flex>

            <v-flex xs6 v-if="type == 'art'">
              <v-combobox
                :readonly="process == 'resolv' || readonly"
                v-model="select_education_level"
                :items="itemsRequestFishermen.items_select_education_level"
                hint="Nivel educativo del pescador"
                persistent-hint
                item-text="state"
                item-value="abbr"
                label="Nivel educativo del pescador"
                hide-selected
                small-chips
              ></v-combobox>
            </v-flex>

            <v-flex xs6 v-if="type == 'art'">
              <v-radio-group
                :readonly="process == 'resolv' || readonly"
                v-model="own_house"
                row
                persistent-hint
                hint="Posee casa propia"
              >
                <v-radio label="Si" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
                <v-radio label="Sin Respuesta" value="NR"></v-radio>
              </v-radio-group>
            </v-flex>

            <v-flex xs6 v-if="type == 'art'">
              <v-combobox
                :readonly="process == 'resolv' || readonly"
                v-model="select_type_health_service"
                :items="itemsRequestFishermen.items_select_type_health_service"
                hide-selected
                item-text="state"
                item-value="abbr"
                hint="Tipo de servicio de salud"
                persistent-hint
                small-chips
              ></v-combobox>
            </v-flex>

            <v-flex xs6 v-if="type == 'art'">
              <v-select
                :readonly="process == 'resolv' || readonly"
                v-model="select_fishing_time"
                prepend-inner-icon="directions_boat"
                hint="Tiempo en la pesca"
                persistent-hint
                :items="itemsRequestFishermen.items_select_fishing_time"
                item-text="state"
                item-value="abbr"
                label="Tiempo en la pesca"
                return-object
                single-line
              ></v-select>
            </v-flex>

            <v-flex xs6 v-if="type == 'art'">
              <v-select
                :readonly="process == 'resolv' || readonly"
                v-model="select_time_in_activity"
                prepend-inner-icon="directions_boat"
                hint="Dedicación"
                persistent-hint
                :items="items_select_time_in_activity"
                item-text="state"
                item-value="abbr"
                label="Dedicación"
                return-object
                single-line
              ></v-select>
            </v-flex>

            <v-flex xs6 v-if="type == 'art'">
              <v-select
                :readonly="process == 'resolv' || readonly"
                v-model="select_fishing_schedule"
                prepend-inner-icon="directions_boat"
                hint="Horario de pesca"
                persistent-hint
                :items="itemsRequestFishermen.items_select_fishing_schedule"
                item-text="state"
                item-value="abbr"
                label="Horario de pesca"
                return-object
                single-line
              ></v-select>
            </v-flex>

            <v-flex xs12 class="mb-4">
              <v-subheader light class="subtitle-1 font-italic font-weight-bold"
                >ANÁLISIS DE LA SOLICITUD</v-subheader
              >
              <v-divider light></v-divider>
            </v-flex>
            <v-divider></v-divider>
            <v-flex xs12 class="mb-4">
              <v-subheader>Observaciones</v-subheader>
              <template-selector-dialog ref="templateSelectorDialogComponent" @templateSelect="observations=$event"></template-selector-dialog>
              <ckeditor
                :readonly="process == 'resolv'"
                :editor="editor"
                v-model="observations"
                :config="editorConfig"
              ></ckeditor>
            </v-flex>
            <v-flex xs12 v-if="type == 'ind'">
              <v-textarea
                hint="Nota del Certificado Industrial"
                label="Nota del Certificado Industrial"
                persistent-hint
                :readonly="process == 'resolv'"
                v-model="nota"
                auto-grow
                rows="1"
              ></v-textarea>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                :readonly="process == 'resolv' || readonly"
                :value="username.firstname + ' ' + username.lastname"
                label="Nombre del Funcionario Responsable del Procedimiento"
                disabled
                persistent-hint
              ></v-text-field>
            </v-flex>

            <v-flex xs6 v-if="type == 'dep'">
              <v-alert
                width="100%"
                class="mt-3"
                type="error"
                dismissible
                v-if="showAlertTasa"
                v-text="messageAlertTasa"
              ></v-alert>
            </v-flex>
            <v-flex xs6 v-if="type == 'dep'">
              <v-text-field
                v-model="annual_rate"
                label="Valor de tasa anual"
                number
                readonly
                persistent-hint
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-checkbox
                :readonly="process == 'resolv'"
                v-model="finalizado"
                label="Solicitud Completada"
                hide-details
                class="shrink mr-2 mt-0"
                value="SI"
              ></v-checkbox>
            </v-flex>
            <v-flex v-if="process == 'resolv'" class="mt-4">
              <v-alert dense type="info" v-if="validateApprove">
                El pescador debe contar con todos los documentos requeridos para
                ser aprobado
              </v-alert>
            </v-flex>
            <v-flex
              xs12
              class="mt-4"
              style="display: flex; justify-content: center"
              v-if="process == 'resolv'"
            >
              <v-btn
                color="green darken-1"
                :disabled="validateApprove"
                text
                @click="Gestionar('APROBAR')"
                >Aprobar</v-btn
              >
              <v-btn color="red white-1" text @click="Gestionar('RECHAZAR')"
                >Rechazar</v-btn
              >
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-alert v-if="wasSaved" type="success"
            >Pescador fue guardado correctamente</v-alert
          >
          <v-alert v-if="wasError" type="info" dismissible
            >Debe llenar todos los campos obligatorios.</v-alert
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="process != 'resolv'"
            text
            type="submit"
            class="indigo darken-4 white--text"
            x-large
          >
            <v-icon>done_all</v-icon>&nbsp;Guardar
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </div>
</template>

<script>
const COMPLETADO = 2;
const PROCESO = 1;
const NEW_PROCCESS = "new";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {
  saveFishermanUrl,
  savePeticion,
  getSMV,
  getHeader,
  photoFishermanUrl,
  GestionarPeticion,
  getBoatsByCompanyUrl,
  getConfig,
} from "../../config.js";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import swal from "sweetalert";

import { email, required, alphaNum } from "vuelidate/lib/validators";
import { shortTypeRequest, fishermanRequestTypes } from "../../enums/requestTypes";
import itemsRequestFishermen from "../../assets/data/items-requests-fishermen";
import {
  getLandingZones,
  getFishingArtToFishermen,
  getActivityTimes
} from "../../services/DataSourceService";

import PreviewLicense from "../../components/fishermen/PreviewLicense.vue"
import TemplateSelectorDialog from "@/views/text-template/TemplateSelectorDialog";
export default {
  props: ["pid", "type", "type_request", "process", "request_id"],
  components: {
    'preview-license': PreviewLicense,
    TemplateSelectorDialog
  },
 
  data() {
    return {
      editorConfig: {
        toolbar: {
          items: ["bold", "italic", "link", "undo", "redo"],
        },
      },
      editor: ClassicEditor,
      itemsRequestFishermen: itemsRequestFishermen,
      shortTypeRequest: shortTypeRequest,
      dialogPermisionario: false,
      permittees: [],
      fishermenBoats: [],
      checkBoats: [],
      name_permissionario: "",
      company_id: null,
      finalizado: "",
      path: photoFishermanUrl,
      showAlertTasa: false,
      messageAlertTasa: "",
      smv: "",
      annual_rate: "",
      observations: "",
      wasSaved: false,
      wasError: false,
      imagePreview: "",
      filed: "",
      fullname: "",
      name: "",
      lastname: "",
      identification_number: "",
      searchFishermen: "",
      fisherman_consecutive: "",
      fishin_card_number: "",
      occre: "RAIZAL",
      no_occre: "",
      email: "",
      phone: "",
      address: "",
      cumple_requisitos: "SI",
      organization_cooperative: "",
      copy_identification_document: "SI",
      copy_occre: "SI",
      accreditation_certificate: "SI",
      copy_sisben: "SI",
      fishing_license_number: "",
      frequent_fishing_area: "",
      report_boat: "",
      read_and_write: "SI",
      nota: "",
      own_house: "SI",
      health_service: "SI",
      fisherma_picture: "SI",
      select_type_of_request: { state: "", abbr: "" },
      select_type_of_card: {
        state: "1. Certificado de Pesca Industrial",
        abbr: "UNO",
      },
      select_location: { state: "", abbr: "" },
      select_department: {
        state:
          "1. DEPARTAMENTO ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA",
        abbr: "UNO",
      },
      select_type_person: { state: "Natural", abbr: "UNO" },
      items_select_type_person: [
        { state: "Natural", abbr: "UNO" },
        { state: "Jurídica", abbr: "DOS" },
      ],
      select_fishing_schedule: { state: "", abbr: "" },
      select_time_in_activity: { state: "", abbr: "" },
      select_fishing_time: { state: "", abbr: "" },
      select_type_health_service: { state: "", abbr: "" },
      select_education_level: { state: "", abbr: "" },
      select_marital_status: { state: "", abbr: "" },
      select_gender: { state: "", abbr: "" },
      select_type_of_fishing: [],
      select_type_of_boat: { state: "", abbr: "" },
      landing_zone: "",
      select_landing_zone: { nombre: "", codigo: "" },
      items_select_landing_zone: [],
      select_organization: { state: "", abbr: "" },
      certification_issued_by: "",
      select_certification_issued_by: { state: "", abbr: "" },
      select_nationality: { state: "Colombia", abbr: "COL" },
      select_type_document: { state: "", abbr: "" },
      select: { state: "", abbr: "" },
      select_titular: { state: "", abbr: "" },
      select_type_accredit: "",
      select_validity_permit: { state: "", abbr: "" },
      select_authorized_zone: { state: "", abbr: "" },
      select_types_fishing: { state: "", abbr: "" },
      select_landing_port: { state: "", abbr: "" },
      select_authorized_species: { state: "", abbr: "" },
      select_fishing_arts: [],
      items_select_fishing_arts: [],
      select_authorized_arts: [{ state: "", abbr: "" }],
      dialog: false,
      birth_date: "",
      date_expedition: new Date().toISOString().substr(0, 10),
      date_filed: new Date().toISOString().substr(0, 10),
      menu_date_filed: false,
      menu_birth_date: false,
      menu_date_expedition: false,
      menu_date_expiration: false,
      menu_dateFormatted: false,
      original_image: "",
      searchNit: "",
      model: false,
      messageNotFoundBoat: "",
      id_boat: null,
      boat_name: "",
      number_boat: "",
      searchValueBoat: "",
      dialogBoat: false,
      boats_find: [],
      headers: [
        {
          text: "Nombre(s)",
          align: "left",
          sortable: false,
          value: "fullname",
        },
        { text: "Tipo Documento", value: "id_type" },
        { text: "Numero Identificacion", value: "identification_number" },
        { text: "No. OCCRE", value: "no_occre" },
        { text: "Nacionalidad", value: "nationality" },
        { text: "Dirección", value: "address" },
        { text: "Telefono", value: "phone" },
        { text: "Fecha Radicado", value: "filing_date" },
        { text: "Actions", value: "action", sortable: false },
      ],
      date_expiration: null,
      expiration: null,
      organizationName: null,
      items_select_time_in_activity: [],
    };
  },
  validations: {
    fullname: {
      required,
    },
    select_type_document: {
      required,
    },
    identification_number: {
      required,
      alphaNum,
    },
    occre: {
      required,
    },
    no_occre: {
      alphaNum,
    },
    select_nationality: {
      required,
    },
    email: {
      email,
    },
    address: {
      required,
    },
    organization_cooperative: {
      required,
    },
    fishing_license_number: {
      required,
    },
    age: {
      required,
      alphaNum,
    },
  },
  computed: {
    fishermanOrg() {
      if ( this.organization_cooperative !== "SI") {
        return "NOT APPLICABLE";
      } else {
        return this.select_organization?.state;
      }
    },
    fishermenLicence() {
      return {
  name: this.name,
  occre: this.occre,
  lastName: this.lastname,
  fishingArts: this.select_fishing_arts,
  expeditionPlace: this.select_location?.state,
  landingZone: this.select_landing_zone?.nombre || this.landing_zone_state  || this.select_landing_zone || '',
  status: this.organization_cooperative ===  'SI' ? 'ASSOCIATED' : 'INDEPENDENT',
  organization: this.fishermanOrg,
  identificationNumber: this.identification_number,
  fishingCardNumber: this.fishin_card_number,
  expeditionDate: this.date_expedition,
  expirationDate: this.date_expiration,
  photo: this.imagePreview

      }
    },
    ...mapGetters(["fishermenData", "reqFishermenData", "allBoats", "allCompanies", "allOrganizations"]),
    ...mapState(["boat"]),
    ...mapMutations({
      setBoatsWithOuthFishermen: "GET_BARCOS",
    }),
    username() {
      return JSON.parse(localStorage.getItem("authUser"));
    },
    age: {
      set() {
        return "";
      },
      get() {
        let today = new Date();
        let birthDate = new Date(this.birth_date);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (this.birth_date == "") {
          return "";
        }
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age < 0) {
          return "";
        } else return age;
      },
    },
    readonly() {
      return (
        this.process == "resolv" ||
        this.type_request == shortTypeRequest.CANCELACION_PERMISO
      );
    },
    disabled() {
      return this.type_request == shortTypeRequest.CANCELACION_PERMISO;
    },
    clearable()
    {
      if(this.type_request == shortTypeRequest.CANCELACION_PERMISO)
      {
        return false;
      }else{
        return this.process !== 'resolv';
      }
    },
    erroresFullname() {
      let errores = [];
      if (!this.$v.fullname.$dirty) {
        return errores;
      }
      if (!this.$v.fullname.required) {
        errores.push("Ingresa el nombre del pescador.");
      }
      return errores;
    },
    erroresSelectTypeDocument() {
      let errores = [];
      if (!this.$v.select_type_document.$dirty) {
        return errores;
      }
      if (!this.$v.select_type_document.required) {
        errores.push("Ingresa el tipo de identificación.");
      }
      return errores;
    },
    erroresIdentificationNumber() {
      let errores = [];
      if (!this.$v.identification_number.$dirty) {
        return errores;
      }
      if (!this.$v.identification_number.required) {
        errores.push("Ingresa el numero de identificación.");
      }
      if (!this.$v.identification_number.alphaNum) {
        errores.push("Acepta solo alfanumerico.");
      }

      return errores;
    },
    erroresOccre() {
      let errores = [];

      if (!this.$v.occre.$dirty) {
        return errores;
      }

      if (!this.$v.occre.required) {
        errores.push("Ingrese OCCRE.");
      }

      return errores;
    },
    erroresNoOccre() {
      let errores = [];

      if (!this.$v.no_occre.$dirty) {
        return errores;
      }

      if (!this.$v.no_occre.alphaNum) {
        errores.push("Acepta solo alfanumerico.");
      }

      return errores;
    },
    erroresSelectNationality() {
      let errores = [];

      if (!this.$v.select_nationality.$dirty) {
        return errores;
      }

      if (!this.$v.select_nationality.required) {
        errores.push("Ingresa la nacionalidad.");
      }

      return errores;
    },
    erroresEmail() {
      let errores = [];

      if (!this.$v.email.$dirty) {
        return errores;
      }

      if (!this.$v.email.email) {
        errores.push("Ingresa dirección de email.");
      }

      return errores;
    },
    erroresAddress() {
      let errores = [];

      if (!this.$v.address.$dirty) {
        return errores;
      }

      if (!this.$v.address.required) {
        errores.push("Ingresa dirección.");
      }

      return errores;
    },
    erroresOrganizationCooperative() {
      let errores = [];

      if (!this.$v.organization_cooperative.$dirty) {
        return errores;
      }

      if (!this.$v.organization_cooperative.required) {
        errores.push("Ingresa dirección.");
      }

      return errores;
    },
    itemsSelectOrganizations() {
      let items = this.allOrganizations; 
      if (this.select_location.abbr) {
        items = this.allOrganizations.filter(org=> (org.location.codigo === this.select_location.abbr && org.abbr_name.search('INDEPENDIENTE') < 0));
        items.push({id: 'isDeclaracionJuramentada', abbr_name: 'DECLARACIÓN JURAMENTADA'});
      }
      return items.map(x => ({abbr: x.id, state: x.abbr_name}));
    },
    erroresAge() {
      let errores = [];

      if (!this.$v.age.$dirty) {
        return errores;
      }

      if (!this.$v.age.required) {
        errores.push("Ingresar Edad.");
      }

      if (!this.$v.age.alphaNum) {
        errores.push("Acepta solo alfanumerico.");
      }

      return errores;
    },
    itemsSelectOrganizationsIndep() {
      let items = this.allOrganizations; 
      if (this.select_location.abbr) {
        items = this.allOrganizations.filter(org=> (org.location.codigo === this.select_location.abbr && org.abbr_name.search('INDEPENDIENTE') < 0));
      }
      return items.map(x => ({abbr: x.id, state: x.abbr_name}));
    },
    validateApprove() {
      const documents = [
        this.copy_identification_document,
        this.accreditation_certificate,
      ];
      if (this.type !== "dep") {
        documents.push(this.copy_occre, this.copy_sisben);
      }
      if (this.type_request === shortTypeRequest.CANCELACION_PERMISO) {
        return false;
      }
      const isValid =
        documents.every((x) => x === "SI") &&
        Boolean(
          this.select_certification_issued_by?.abbr ||
            this.certification_issued_by
        );
      return !isValid;
    },
    canApprove(){
      if (this.type === 'ind') {
        return this.$can('aprobarProcedimientosIndustriales')
      }
      else if (this.type === 'art') {
        return this.$can('aprobarProcedimientosArtesanales')
      } else {
         return this.$can('aprobarProcedimientosDeportivos')
      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
   select_organization(value){
  this.organizationName = this.itemsSelectOrganizations.find(x => x.abbr == value);
    },
  },
  created: async function () {
    if (!this.canApprove && this.process === 'resolv') {
      this.$router.push({name: "not-autorized"})
    }
    if (this.type == "art" || this.type == "dep") {
      if (!this.allOrganizations.length) {
        await this.getOrganizations();
      }
    }
    this.axios.get(getConfig, { headers: getHeader() }).then((response) => {
      this.$store.commit("SETTINGS", response.data);
      this.settings = response.data;
    });
    this.axios.get(getSMV, { headers: getHeader() }).then((response) => {
      this.smv = response.data[0].valor;
      if (this.type == "dep") {
        this.Calculate();
      }
    });
    if (this.process == "new") {
      await this.$store.dispatch("getFishermen", { _id: this.pid });
      if (
        this.process == "new" &&
        this.fishermenData.solicitud != null &&
        this.fishermenData.solicitud.estado < 3
      ) {
        this.$router.go(-1);
      } else {
        this.setRequest(this.fishermenData.data);
      }
      //this.$store.dispatch("setLoader", false);
      this.$store.commit("STOP_LOADER");
    } else {
      await this.$store.dispatch("getRequestFishermen", {
        _id: this.request_id,
      });
      this.reqFishermenData && this.setRequest(this.reqFishermenData.data);
    }
    this.items_select_landing_zone = (await getLandingZones())?.data || [];
    this.items_select_time_in_activity =  (await getActivityTimes())?.data || [];
    this.items_select_fishing_arts =
      (await getFishingArtToFishermen())?.data || [];
    this.items_select_fishing_arts = this.items_select_fishing_arts.filter(
      (x) => x.codigo !== "CINCO"
    );
  },
  methods: {
    ...mapActions(['getOrganizations']),
    getParamsCompany(){
      return {
        itemsPerPage: 5,
        ...this.searchNit && {search: this.searchNit}
      }
    },
    validateOrganization()
    {
      if(this.select_certification_issued_by == 'isDeclaracionJuramentada')
      {
        this.certification_issued_by = 'DECLARACIÓN JURAMENTADA';
        this.organization_cooperative = 'NO';
        this.hiddenOrganization();
      }
    },
    hiddenOrganization()
    { 
      let buscar =  '';

      if(this.organization_cooperative == 'NO')
      {
        let items = this.allOrganizations; 

        if (this.select_location.abbr) {
          buscar = (this.select_location.abbr == 'UNO') ?  'INDEPENDIENTE SAI' : 'INDEPENDIENTE PROV';
          items = this.allOrganizations.find(element => element.name == buscar);
        }
        this.select_organization = {abbr: items.id, state: items.abbr_name};
      } else {
        this.select_organization = null; 
      }
    },
    cleanInputBoat()
    {
      if(this.report_boat == 'NO')
      {
        this.fishermenBoats = [];
      }
    },
    getParamsBoat(){
      return {
        itemsPerPage: 5,
        ...this.searchValueBoat && {search: this.searchValueBoat}
      }
    },
    async searchBoat() {
      this.messageNotFoundBoat = "";
      if(this.searchValueBoat) {
        await this.$store.dispatch("getBoats", {type: this.type.toUpperCase(), options: this.getParamsBoat()});
        this.boats_find = this.allBoats.data;
        if (this.boats_find.length == 0)
          this.messageNotFoundBoat = " No se encontró ninguna embarcación";
      } else {
        this.boats_find = [];
      }

    },
    setBoat(item) {
      if(this.owns_boat == 1)
      {
        if((item.owner_identification != null) && (item.owner_identification != this.identification_number))
        {
          this.$toasted.show(`La embarcación seleccionada ya cuenta con un propietario`, {
            theme: "bubble",
            position: "top-right",
            duration: 2000,
            action: {
              text: "",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
          return;
        }
      }
    
      if (!this.select_type_accredit || !this.owns_boat) {
        this.$toasted.show(`Diligencie todos los datos`, {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
          action: {
            text: "",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
        return;
      }

      let existsBoat = this.fishermenBoats.filter((value) => value.identification_number == item.identification_number);
      if(existsBoat.length > 0)
      {
        this.$toasted.show(`El bote ya fue registrado`, {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
          action: {
            text: "",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
        return;
      }

      this.searchValueBoat = "";
      this.boats_find = [];

      this.fishermenBoats.push({
        owner_acredit: this.select_type_accredit,
        owns_boat: this.owns_boat,
        original_boat_id: item.id,
        registration_number: item.registration_number,
        boat_name: item.boat_name,
      });

      this.searchValueBoat = "";
      this.owns_boat = "";
      this.select_type_accredit = "";
      this.dialogBoat = false;
    },
    removeBoat(index) {
      this.fishermenBoats.splice(index, 1);
    },
    async searchPermisionario() {
      await this.$store.dispatch("getCompanies", {type: "IND", options: this.getParamsCompany()});
      this.permittees = this.allCompanies.data;
      this.searchNit = '';
    },
    selectBoat(item){
      this.name_permissionario = item.name;
      this.company_id = item.nit;
      this.dialogPermisionario = false;
      this.permittees = [];
      this.checkBoats = [];
      this.reloadBoats()
    },
    reloadBoats: async function () {
      this.fishermenBoats = [];
      await this.axios
        .get(
          getBoatsByCompanyUrl +
            "/" +
            this.company_id +
            "/" +
            "pescador/" +
            this.type_request,
          {
            headers: getHeader(),
          }
        )
        .then((response) => (this.fishermenBoats = response.data.data));
    },
    checkBoat() {
      if (this.checkBoats.length > 1) {
        this.checkBoats.pop();
      }
    },
    dateFormatted() {
      var d = new Date(this.date_expedition);

      var year = d.getFullYear();
      var month = d.getMonth();
      var day = d.getDate();
      var c = "";
      if (this.type != "ind") {
        c = new Date(
          year + parseInt(this.select_validity_permit.state),
          month,
          day
        );
      } else {
        c = new Date(
          year,
          month + parseInt(this.select_validity_permit.state),
          day
        );
      }

      this.date_expiration = c.toISOString().substr(0, 10);
    },
    Calculate() {
      let nsmv;
      if (
        this.select_type_person.abbr == "UNO" &&
        this.select_nationality.abbr == "COL"
      ) {
        nsmv = 3;
      } else if (
        this.select_type_person.abbr == "DOS" &&
        this.select_nationality.abbr == "COL"
      ) {
        nsmv = 60;
      } else if (this.select_nationality != "COL") {
        nsmv = 30;
      }

      if (this.select_type_person.abbr.length == 0) {
        return false;
      } else if (this.select_nationality.abbr.length == 0) {
        return false;
      } else {
        this.annual_rate = Intl.NumberFormat().format(this.smv * nsmv);
      }
      setTimeout(() => {
        this.showAlertTasa = false;
      }, 5000);
    },
    getImage(file) {
      this.imagen = file;
      this.loadImage(file);
    },
    clearPhoto() {
      this.imagen = null;
      this.imagePreview = null;
    },
    loadImage(file) {
      if (file) {
        let reader = new FileReader();

        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };

        reader.readAsDataURL(file);
      }
    },
    getTypeCard(type) {
      return {
        art: "UNO",
        dep: "DOS",
        ind: "TRES",
      }[type];
    },
    getCodeOfTypeRequest(type) {
      return {
        [`${shortTypeRequest.NUEVO}`]: fishermanRequestTypes.NUEVO,
        [`${shortTypeRequest.PRORROGA}`]: fishermanRequestTypes.PRORROGA,
        [`${shortTypeRequest.DUPLICADO}`]: fishermanRequestTypes.DUPLICADO,
        [`${shortTypeRequest.CANCELACION_PERMISO}`]:
          fishermanRequestTypes.CANCELACION_PERMISO,
        [`${shortTypeRequest.REACTIVACION_PERMISO}`]:
          fishermanRequestTypes.REACTIVACION_PERMISO,
        none: fishermanRequestTypes.NUEVO,
      }[type];
    },
    getValueSelect(value) {
      if (typeof value === 'number') {
        return value
      }
      else if (typeof value === 'object'){
        return value?.abbr || null;
      }
    },
    buildBody() {
      const isBase64 = Boolean(this.imagePreview?.indexOf("data:image") >= 0);
      const fishermenPhoto = isBase64 ? this.imagePreview : this.original_image;
      return {
        fisherman_photo_file: fishermenPhoto,
        filed: this.filed,
        estado: this.finalizado == "SI" ? COMPLETADO : PROCESO,
        department: this.select_department.abbr,
        location: this.select_location.abbr,
        filing_date: this.date_filed,
        type_of_card: this.getTypeCard(this.type),
        type_of_request: this.getCodeOfTypeRequest(this.type_request),
        name: this.name,
        lastname: this.lastname,
        id_type:
          this.getValueSelect(this.select_type_document) ||
          this.select_type_document,
        identification_number: this.identification_number,
        type_person: this.getValueSelect(this.select_type_person),
        occre: this.occre,
        no_occre: this.no_occre,
        nationality: this.getValueSelect(this.select_nationality),
        email: this.email,
        address: this.address,
        phone: this.phone,
        organization_cooperative: this.organization_cooperative,
        organization_name: this.getValueSelect(this.select_organization),
        copy_identification_document: this.copy_identification_document,
        copy_occre: this.copy_occre,
        accreditation_certificate: this.accreditation_certificate,
        certification_issued_by: this.select_certification_issued_by == 'isDeclaracionJuramentada' ? '' : this.getValueSelect(this.select_certification_issued_by),
        certification_issued_by_state: this.certification_issued_by,
        copy_sisben: this.copy_sisben,
        expedition_date: this.date_expedition,
        expiration_date: this.date_expiration,
        landing_zone: this.select_landing_zone?.codigo || "",
        landing_zone_state: this.landing_zone,
        frequent_fishing_area: this.frequent_fishing_area,
        report_boat: this.report_boat,
        birth_date: this.birth_date,
        type_of_artesanal_boat:
          this.getValueSelect(this.select_type_of_boat) || "unknow",
        type_of_artesanal_boat_state: this.getValueSelect(
          this.select_type_of_boat
        ),
        types_of_fishery: this.select_type_of_fishing,
        types_of_fishery_state: this.select_type_of_fishing.state,
        fishing_arts: this.select_fishing_arts,
        age: this.age,
        gender: this.getValueSelect(this.select_gender),
        marital_status: this.getValueSelect(this.select_marital_status),
        read_and_write: this.read_and_write,
        education_level: this.getValueSelect(this.select_education_level) || this.select_education_level,
        own_house: this.own_house,
        health_service: this.health_service,
        type_health_service:
          this.getValueSelect(this.select_type_health_service) || "unknow",
        type_health_service_state: this.getValueSelect(
          this.select_type_health_service
        ),
        fishing_time: this.getValueSelect(this.select_fishing_time),
        time_in_activity: this.getValueSelect(this.select_time_in_activity),
        fishing_schedule: this.getValueSelect(this.select_fishing_schedule),
        fishermans_picture: this.fisherma_picture,
        annual_rate: this.annual_rate,
        company_id: this.company_id,
        boat_id: this.checkBoats.length > 0 ? this.checkBoats[0] : null,
        nombre_secretario: `${
          this.$store.state.settings.find(
            (x) => x.nombre == "nombre_secretario"
          )?.valor
        }`,
        firma_secretario: `${
          this.$store.state.settings.find((x) => x.nombre == "firma_secretario")
            .valor
        }`,
        observations: this.observations,
        nota: this.nota,
        boats: this.type == "art" ? this.fishermenBoats : [],
      };
    },
    submit: async function () {
      const postData = this.buildBody();
      const obj = {
        fisherman_id: this.pid,
        fishin_card_number: this.fishin_card_number,
        ...(this.process !== NEW_PROCCESS && { _id: this.request_id }),
      };
      Object.assign(obj, postData);
      const url = !this.pid ? saveFishermanUrl : savePeticion;
      const body = !this.pid ? postData : obj;
      body.type = this.type;
      await this.axios.post(url, body, { headers: getHeader() });
      this.$router.push({ path: `/request-fisherman-${this.type}` });
    },
    async setRequest(item) {
      if (!item) {
        return;
      }
      if (item.boat_id != null) this.checkBoats.push(Number(item.boat_id));

      this.company_id = item.company_id;
      this.date_filed = new Date(item.filing_date).toISOString().substr(0, 10);
      if (this.type == "ind") {
          this.searchNit = this.company_id;
          await this.searchPermisionario();
          this.name_permissionario = this.allCompanies.data.find(
            (x) => x.nit == this.company_id
          )?.name;
          this.reloadBoats();
      }

      if (this.process != "new") this.filed = item.filed;

      if (this.type_of_request != "UNO" && item.estado == 2) {
        this.finalizado = "SI";
      }

      if (item.expedition_date != null && item.expiration_date != null) {
        var year1 = new Date(item.expiration_date).getFullYear();
        var m1 = new Date(item.expiration_date).getMonth();
        var year2 = new Date(item.expedition_date).getFullYear();
        var m2 = new Date(item.expedition_date).getMonth();

        let vigency;
        if (this.type != "ind") {
          vigency = itemsRequestFishermen.items_select_validity_permit.find(
            (x) => x.state == parseInt(year1) - parseInt(year2)
          );
        } else {
          vigency = itemsRequestFishermen.items_select_validity_permit.find(
            (x) => x.state == parseInt(m1) - parseInt(m2)
          );
        }

        if (vigency != undefined) {
          this.select_validity_permit = vigency;
        }
      }

      this.fishin_card_number = item.fishin_card_number;

      if (item.expedition_date != null) {
        if (this.type_request == shortTypeRequest.PRORROGA) {
          if (this.process == "new") {
            this.date_expedition = new Date(item.expiration_date)
              .toISOString()
              .substr(0, 10);
          } else {
            this.date_expedition = new Date(item.expedition_date)
              .toISOString()
              .substr(0, 10);
          }
        } else {
          this.date_expedition = new Date(item.expedition_date)
            .toISOString()
            .substr(0, 10);
        }
      }

      if (item.expiration_date != null) this.expiration = item.expiration_date;
      this.date_expiration = item.expiration_date;

      this.p_id = item.id;
      this.pescador = item.name + " " + item.lastname;
      this.name = item.name;
      this.lastname = item.lastname;

      if (item.id_type != null)
        this.select_type_document =
          itemsRequestFishermen.items_select_type_document.find(
            (x) => x.abbr == item.id_type
          );

      if (this.select_type_document == undefined)
        this.select_type_document = item.id_type;
      this.identification_number = item.identification_number;
      this.occre = item.occre;
      this.no_occre = item.no_occre;

      if (item.nationality != null)
        this.select_nationality =
          itemsRequestFishermen.items_select_nationality.find(
            (x) => x.abbr.replace(" ", "") == item.nationality
          );

      this.email = item.email;
      this.birth_date = item.birth_date;
      this.address = item.address;
      this.phone = item.phone;
      this.age = item.age;
      this.fishermenBoats = [
        ...item.boats.map((x) => ({
          ...x,
          ...(!x.original_boat_id && { original_boat_id: x.id }),
        })),
      ];
      this.frequent_fishing_area = item.frequent_fishing_area;
      this.owns_boat = item.owns_boat;
      this.report_boat = item.report_boat;

      this.own_house = item.own_house;
      this.read_and_write = item.read_and_write;
      if (this.process != "new") {
        this.observations = item.observations;
        this.nota = item.nota;
      }

      if (item.location != null)
        this.select_location = itemsRequestFishermen.items_select_location.find(
          (x) => x.abbr == item.location
        );

      if (item.gender != null)
        this.select_gender = itemsRequestFishermen.items_select_gender.find(
          (x) => x.abbr == item.gender
        );
      if (item.type_person != null)
        this.select_type_person = this.items_select_type_person.find(
          (x) => x.abbr == item.type_person
        );

      if (item.time_in_activity != null && item.time_in_activity != "CERO")
        this.select_time_in_activity =
          itemsRequestFishermen.items_select_time_in_activity.find(
            (x) => x.abbr == item.time_in_activity
          );

      if (item.fishing_schedule != null && item.fishing_schedule != "CERO")
        this.select_fishing_schedule =
          itemsRequestFishermen.items_select_fishing_schedule.find(
            (x) => x.abbr == item.fishing_schedule
          );

      if (item.puerto_desembarque) {
        this.select_landing_zone = item.puerto_desembarque;
      } else if (item.landing_zone_state != null)
        this.select_landing_zone = item.landing_zone_state;
      if (item.landing_zone_state != null)
        this.landing_zone = item.landing_zone_state;

      if (item.type_of_artesanal_boat != null)
        this.select_type_of_boat =
          itemsRequestFishermen.items_select_type_of_boat.find(
            (x) => x.abbr == item.type_of_artesanal_boat
          );
      else if (item.type_of_artesanal_boat_state != null)
        this.select_type_of_boat = item.type_of_artesanal_boat_state;
      this.select_fishing_arts = [
        ...((item.arte_pesca?.codigo !== "CINCO" && [item.arte_pesca]) || []),
        ...item.fishing_arts,
        ...[item.fishing_arts_state],
      ].filter(Boolean);

      if (item.type_fishery.length > 0 || item.type_fishery != null)
        this.select_type_of_fishing = item.type_fishery;

      if (item.marital_status != null) {
        this.select_marital_status =
          itemsRequestFishermen.items_select_marital_status.find(
            (x) => x.abbr == item.marital_status
          );
      }

      if (item.type_health_service != null)
        this.select_type_health_service =
          itemsRequestFishermen.items_select_type_health_service.find(
            (x) => x.abbr == item.type_health_service
          );
      else if (item.type_health_service_state != null)
        this.select_type_health_service = item.type_health_service_state;

      if (item.fishing_time != null)
        this.select_fishing_time =
          itemsRequestFishermen.items_select_fishing_time.find(
            (x) => x.abbr == item.fishing_time
          );
      else if (item.fishing_time_state != null)
        this.select_fishing_time = item.fishing_time_state;

      this.select_education_level = this.mapToAbbr(item.nivel_educativo) || item.education_level;

      this.original_image = item.fisherman_photo_file;
      this.imagePreview =
        item.fisherman_photo_file != null
          ? this.path + item.fisherman_photo_file
          : "";
      this.accreditation_certificate = item.accreditation_certificate;
      this.copy_identification_document = item.copy_identification_document;
      this.copy_occre = item.copy_occre;
      this.copy_sisben = item.copy_sisben;
      this.organization_cooperative = item.organization_cooperative;
      this.select_organization = {abbr: item.organization?.id, state: item.organization?.abbr_name}
      if (item?.certifying_organization) { 
        this.select_certification_issued_by =  {
          abbr: item.certifying_organization?.id, 
          state: item.certifying_organization?.abbr_name
        }
      }
      else this.certification_issued_by = item?.certification_issued_by_state;
    },
    Gestionar(procedimiento) {
      let obj = [];

      swal("Seguro desea continuar", {
        buttons: {
          cancel: "Cancelar",

          Si: true,
        },
      }).then((value) => {
        switch (value) {
          case "Si":
            obj = {
              _id: this.request_id,
              respuesta: procedimiento,
            };
            if (!this.validateApprove) {
              this.axios
                .post(GestionarPeticion, obj, { headers: getHeader() })
                .then(() =>
                  this.$router.push({ path: `/request-fisherman-${this.type}` })
                );
            }
            break;
          default:
            return false;
        }
      });
    },
    setValidateCertificationIssuedBy() {
      if (this.accreditation_certificate !== "SI") {
        this.select_certification_issued_by = null;
        this.certification_issued_by_state = "";
      }
    },
    mapToAbbr(obj){
      if (!obj) return null;
      return {abbr: obj.codigo, state: obj.nombre};
    }
  },
};
</script>
