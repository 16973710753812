<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="blue-grey"
        dark
        v-bind="attrs"
        v-on="on"
        class="mb-2"
      >
        Plantillas
        <v-icon
          right
          dark
        >
          mdi-format-list-bulleted-square
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline indigo darken-4 white--text">
        <span class="text-h5">
          <v-icon right dark>mdi-format-list-bulleted-square</v-icon>
          Plantillas
        </span>
      </v-card-title>
      <v-card-text>
        <v-row v-for="(template, i) in templates" :key="i" class="pt-2">
          <v-col cols="11">
            <h4 class="mb-0">{{template.document_type}}</h4>
            <p class="m-1 template-text" v-html="template.text"></p>
          </v-col>
          <v-col cols="1">
            <v-btn
              @click="selectTemplate(template.text)"
              class="mx-2"
              fab
              dark
              small
              color="indigo darken-4"
            >
              <v-icon dark>
                mdi-check-all
              </v-icon>
            </v-btn>
          </v-col>
          <v-divider></v-divider>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="dialog=false"
        >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getTextTemplatesAll } from "@/services/textTemplateService";

export default {
  name: "TemplateSelectorDialog",
  data(){
    return {
      dialog : false,
      templates : [],
    }
  },
  created (){
    this.getTemplates();
  },
  methods : {
    async getTemplates(){
      const response = await getTextTemplatesAll();
      this.templates = response.data;
    },
    async open(){
      this.$store.commit("LOADER", true);
     // this.$store.commit("START_LOADER");
      await this.getTemplates();
      this.$store.commit("LOADER", false);
      //this.$store.commit("STOP_LOADER");
    },
    selectTemplate(text){
      this.$emit('templateSelect', text);
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
.template-text{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>